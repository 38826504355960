import styles from 'templates/message-sent/messageSent.module.scss';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import SimilarTrials from 'components/SimilarTrials/SimilarTrials';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Link from '@moonshineragency/ui/src/components/Link/Link';

const MessageSentTemplate = ({ location }) => {
  useEffect(() => {
    if (!location?.state?.form) {
      navigate('/');
    }
  }, []);
  if (!location?.state?.form) {
    return null;
  }
  const trial = location?.state?.trialData.trial;
  const { email } = location?.state?.form;

  const updatedDate = location?.state?.trialData?.trial?.modified_at;

  return (
    <Layout isContentpage>
      <Container size="lg">
        <div className={styles.messageSentHeading}>
          <IconWrapper Icon={IconComponents.LargeEmaiLFastIcon} alt="login" />
          <Heading theme="primary" align="center" size="h1big" noSpacing>
            Message sent
          </Heading>
        </div>
        <div className={styles.messageSentTextWrapper}>
          <Content size="copy100">
            {`to ${location.state.passedName} at ${location.state.passedLocation}`}
          </Content>
          <Content size="copy100">A copy has been sent to {email}</Content>
          {trial && (
            <Link
              to={`/trials/${trial.nct_id}`}
              className={styles.actionTextLink}
            >
              Back to trial
            </Link>
          )}
        </div>
        <SimilarTrials
          formValues={location?.state?.form}
          trialModified={updatedDate}
        />
      </Container>
    </Layout>
  );
};

export default MessageSentTemplate;
